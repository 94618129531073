.footer_block {
  width: 100%;
  height: 225px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  margin-bottom: 100px;

  .first {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px!important;
    padding-bottom: 20px;
    border-bottom: 1px solid #5E6B74;

    p {
      width: 323px;
      height: 32px;
      font-family: 'Roboto';
      font-size: 10px;
      line-height: 16px;
      color: #5E6B74;
      border: none;
    }

    .first_div {
      display: flex;
      align-items: center;
      gap: 54px;
      height: 48px;

      p {
        height: auto;
      }
    }
    .first_div_2 {
      display: flex;
      align-items: center;
      gap: 20px;
      height: 48px;

      p {
        font-family: "Roboto";
        height: auto;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;



      a {
        width: 20px;
        height: 20px;
        align-content: center;

        li {

          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .2s ease;
          cursor: pointer;
          align-self: center;
          transform: translateY(0px);

          &:hover {
            transform: scale(1.1);
          }



        }
      }
    }
  }


    .footer_about {
      width: 100%;
      height: 80px;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #5E6B74;
      border: none;

    }

}







@media screen and (max-width: 767px) {

  .footer_block {
    width: 328px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    margin-bottom: 0;

    .first {

      width: 100%;
      height: 200px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #5E6B74;

      p {
        width: 200px;
        height: 32px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #5E6B74;
        border: none;
      }

      .first_div {
        display: flex;
        align-items: flex-start;
        gap: 54px;
        height: 48px;

        p {
          height: auto;
        }
      }

      .first_div_2 {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 48px;

        p {
          font-family: "Roboto";
          height: auto;
        }
      }
    }


    ul {

      width: 328px;
      align-self: center;
      justify-content: flex-start!important;
      margin-left: 0px;
      margin-bottom: 0px;
      gap: 0;



        li {
          width: 48px;

        }
      }


    .line {
      position: relative;
      width: 320px;
      height: 20px;
      border-top: 1px solid #5E6B74;
      margin-bottom: 10px;

    }

    .footer_about {
      width: 328px;
      height: 304px;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #5E6B74;
      text-align: start;
      margin-bottom: 20px;

    }


  }
}


