header {
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &::after {
    position: absolute;
    background: url('../../assets/background.png') center/cover no-repeat;
    width: 855px;
    height: 632px;
    content: '';
    top: 0;
    left: 53%;
    transform: translate(-53%, 20%);
    z-index: 1;
    pointer-events: none;
  }

  .header_menu_block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 120px;


    .button_block {
      width: 281px;
      height: 58px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      .open_account {

        width: 133px;
        height: 40px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #919CA2;
        background: transparent;
        gap: 2px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: center;
        color: #D9DEE2;
        cursor: pointer;

      }
    }
  }

  .header_block_wrapped {
    width: 100%;
    height: 574px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    overflow: hidden;
    margin-bottom: 120px;

    .header_wrapped_up_block {
      width: 100%;
      height: 296px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;


      .header_title {
        width: 580px;
        font-family: 'Prompt';
        font-size: 48px;
        line-height: 62px;
        color: #FFFFFF;
      }

      .header_about {
        width: 429px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.15px;
        color: #919CA2;
        text-align: start;
      }

      .trade_gold_button {
        width: 180px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        background: #F0A719;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        border: none;
      }
    }

    .header_wrapped_down_block {
      width: 1180px;
      height: 100px;
      padding: 32px;
      border-radius: 32px;
      background: #1F1C10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .wrapped_down_vector_block {

        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        .about_vector {
          width: auto;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #FFFFFF;
        }
      }
    }
  }

  .line_block {
    width: 100%;
    height: 444px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 60px;
    margin-bottom: 120px;

    &::before {
      position: absolute;
      background: url('../../assets/ellipse.png') center/cover no-repeat;
      width: 2120px;
      height: 1179px;
      content: '';
      top: -210%;
      left: -38%;
      transform: rotate(0deg);
      z-index: 1;
      pointer-events: none;
    }
    &::after {
      position: absolute;
      background: url('../../assets/ellipse.png') center/cover no-repeat;
      width: 2120px;
      height: 1179px;
      content: '';
      top: 85%;
      left: -47%;
      transform: rotate(40deg);
      z-index: 1;
      pointer-events: none;
    }

    .line_up_block {
      height: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .line_up_block_title {
        width: 780px;
        height: 52px;
        font-family: 'Prompt';
        font-weight: 500;
        font-size: 40px;
        line-height: 52px;
        text-align: center;
        color: #FFFFFF;
      }

      .line_up_block_about {
        height: 24px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #D9DEE2;
      }
    }

    .line_mid_block {
      display: flex;
      align-items: center;
      gap: 20px;

      .mid_block_div {
        height: 172px;
        border-radius: 32px;
        border: 1px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(241.16deg, #1F1C10 3.83%, #503D0B 47.84%, #1F1C10 96.7%);

        .investment_line {
          width: 756px;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 30px;

          .slider_prices {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .slider_price {
              font-family: 'Roboto';
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
              color: #5E6B74;
            }
            .slider_price2 {
              color: #FFFFFF;
            }
          }

          .slider {
            transform: translateY(100%);
            width: 100%;
            -webkit-appearance: none;
            -webkit-transition: 0.2s;
            border-radius: 20px;
            height: 20px;
            background: #F0A719;
            outline: none;
            position: relative;

          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background: #FFFFFF;
            transition: .1s;

          }

          .slider::-moz-range-thumb {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background: #FFFFFF;
            transition: .1s;
          }

          .slider::-webkit-slider-thumb:hover,
          .slider::-webkit-slider-thumb:active {
            width: 39px;
            height: 39px;
            cursor: pointer;
          }

          .sliderValue {
            transform: translateY(125%);
            top: 0;
            left: 0;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #FFFFFF;
          }


        }

        .mid_block_div_investment {
          transform: translateY(30%);
          margin-right: auto;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
        }

        .mid_block_div_profit {
          transform: translateY(30%);
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
        }

        .mid_block_div_money {
          width: 276px;
          height: 62px;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 64px;
          line-height: 76px;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
    .start_trading_now_button {

      width: 180px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;
      background: #F0A719;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      border: none;
    }
  }
}

@media screen and (max-width: 1660px) {

  header {
    position: relative;

    &::after {
      position: absolute;
      background: url('../../assets/background.png') center/cover no-repeat;
      width: 855px;
      height: 632px;
      content: '';
      top: 0;
      left: 80%;
      transform: translate(-80%, 20.5%);
      z-index: 1;
    }
  }

}

@media screen and (max-width: 767px) {

  header {
    padding-top: 16px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;




      &::after {
        position: absolute;
        background: url('../../assets/mobile_background.png') center/cover no-repeat;
        width: 360px;
        height: 380px;
        content: '';
        top: 34%;
        left: 50%;
        transform: translate(-50%, -34%);
        z-index: 1;
        pointer-events: none;
      }

    .header_menu_block {

      width: 328px;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      gap: 12px;
      margin: 0;

      .button_block {
        width: 133px;
        height: 58px;
        justify-content: flex-end;
        align-items: center;
        gap: 0;

        img {

          transform: translateY(0px);
        }

        .open_account {
          width: 133px;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #919CA2;
          background: transparent;
          gap: 2px;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          text-align: center;
          color: #D9DEE2;
          padding: 0px 4px 0px 4px;
          cursor: pointer;
        }
      }
    }


    .header_block_wrapped {
      width: 360px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
      overflow: hidden;
      margin-bottom: 40px;

      .header_wrapped_up_block {
        width: 328px;
        height: 296px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 40px;
        margin-bottom: 300px;

        .header_title {
          width: 328px;
          font-family: 'Prompt';
          font-size: 48px;
          line-height: 62px;
          color: #FFFFFF;
        }

        .header_about {
          width: 328px;
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #919CA2;
          text-align: start;
        }

        .trade_gold_button {

          width: 180px;
          height: 56px;
          border-radius: 8px;
          padding: 16px 0px 16px 0px;
          background: #F0A719;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-align: center;
          color: #FFFFFF;
          cursor: pointer;
          border: none;

        }
      }

      .header_wrapped_down_block {
        width: 328px;
        height: 150px;
        border-radius: 20px;
        background: #1F1C10;
        padding: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;


        .wrapped_down_vector_block {
          width: 130px;
          height: 48px;
          display: flex;
          align-items: baseline;
          justify-content: center;
          gap: 12px;
          padding: 0;
          margin: 0;

          img {
            margin: 0;
          }

          .about_vector {
            width: 102px;
            height: 48px;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            align-self: start;
          }
        }
      }
    }

    .line_block {
      width: 328px;
      height: 542px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      .line_up_block {

        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .line_up_block_title {
          width: 328px;
          height: 80px;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 28px;
          line-height: 40px;
          text-align: center;
          color: #FFFFFF;
        }

        .line_up_block_about {
          width: 328px;
          height: 48px;
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          text-align: center;
          color: #D9DEE2;
        }
      }

      .line_mid_block {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;


        .mid_block_div {

          width: 328px;
          height: 130px;
          border-radius: 24px;
          border: 1px;
          padding: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
          linear-gradient(241.16deg, #1F1C10 3.83%, #503D0B 47.84%, #1F1C10 96.7%);

          .investment_line {
            width: 296px;
            height: auto;
            display: flex;
            flex-direction: column;

          }

          .mid_block_div_investment {
            transform: translate(-10%,50%);
            margin-right: auto;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
          }

          .mid_block_div_profit {
            transform: translateY(50%);
            height: 24px;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
          }

          .mid_block_div_money {
            width: 211px;
            height: 62px;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 48px;
            line-height: 76px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }

      .start_trading_now_button {

      }
    }
  }
}