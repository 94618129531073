main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main_icons_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    z-index: 2;

    h3 {
      width: 780px;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 60px;
    }

    .main_icons {
      height: 260px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
      z-index: 2;

      .main_icon {
        width: 380px;
        height: 260px;
        border-radius: 32px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #1F1C10;
        gap: 20px;

        .icon_title {

          font-family: 'Prompt';
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          text-align: center;
          color: #FFFFFF;

        }

        .about {
          width: 316px;
          height: 48px;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #919CA2;
        }
      }
    }
  }

  .start_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    position: relative;
    z-index: 2;


    &::after {
      position: absolute;
      background: url('../../assets/ellipse.png') center/cover no-repeat;
      width: 2120px;
      height: 1179px;
      content: '';
      top: 0%;
      left: -38%;
      transform: rotate(0deg);
      z-index: 1;
      pointer-events: none;
    }

    .start_block_title {
      width: 248px;
      height: 52px;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      color: #FFFFFF;
      margin-bottom: 60px;
    }

    .start_block_items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      z-index: 2;

      .start_block_item {
        width: 380px;
        height: 608px;
        border-radius: 32px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        background: #1F1C10;

        .start_block_item_about {
          width: 316px;
          height: 80px;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 28px;
          line-height: 40px;
          color: #FFFFFF;
          text-align: start;
        }
        .start_block_item_about2 {
          width: 200px;
          height: 50px;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 28px;
          line-height: 40px;
          color: #FFFFFF;
          text-align: start;
          border-radius: 32px;
          padding: 5px 9px;
          background: #F0A719
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  main {
    gap: 40px;
    height: auto;
    .main_icons_block {
      width: 360px;
      height: auto;
      margin: 0px;

      h3 {
        width: 328px;
        height: 80px;
        font-family: 'Prompt';
        font-weight: 500;
        font-size: 28px;
        line-height:40px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 40px;
      }

      .main_icons {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        z-index: 2;

        .main_icon {
          width: 328px;
          height: 180px;
          border-radius: 24px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: #1F1C10;
          gap: 12px;

          .icon_title {

            font-family: 'Prompt';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;

          }

          .about {
            width: 296px;
            height: 40px;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            text-align: center;
            color: #919CA2;
          }
        }
      }
    }

    .start_block {


      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      position: relative;
      z-index: 2;


      &::after {
       display: none;
      }

      .start_block_title {
        width: 174px;
        height: 40px;
        font-family: 'Prompt';
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        color: #FFFFFF;
        margin-bottom: 40px;
      }

      .start_block_items {

        width: 328px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        z-index: 2;

        .start_block_item {
          width: 328px;
          height: auto;
          border-radius: 24px;
          padding: 16px 16px 0px 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          background: #1F1C10;

          .start_block_item_about {
            width: 296px;
            height: auto;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #FFFFFF;
            text-align: start;
          }
          .start_block_item_about2 {
            width: 150px;
            height: 35px;
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #FFFFFF;
            text-align: start;
            border-radius:32px;
            padding: 5px 9px;
            background: #F0A719
          }
        }
      }
    }
  }


}